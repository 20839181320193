<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <h1>Stripe Payments</h1>
    <p>Stripe is a third-party credit card processor that can be connected with Bunya to let your clients pay you.</p>
    <hr>
    <div v-if="connected && false">
      <b-message type="is-success">
        <strong>Connected to Stripe</strong>
      </b-message>
    </div>
    <div v-else>
      <div class="columns">
        <div class="column is-two-thirds">
          <h3>Let your customers pay with their credit card!</h3>
          <p>Stripe has no monthly fees, is easy to setup, and let's you receive credit card payments securely.</p>
          <p>Click the button below to start setting up a Stripe account.</p>
          <p>Once Stripe is connected to your Bunya account a credit card payment option will appear on any invoice you send.</p>
          <p></p>
          <a class="button is-primary" :href="stripeConnectUrl" v-if="stripeConnectUrl">Connect To Stripe</a>
        </div>
        <div class="column is-one-third stripe-logo">
          <img src="../../assets/stripe.svg">
        </div>
      </div>
    </div>
  </div>
</template>

<style>

.stripe-logo img {
  max-width: 10rem;
  float: right;
}

</style>

<script>

import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'Stripe',
  data () {
    return {
      breadcrumbs: [
        { name: 'Settings', route: 'settings-home' },
        { name: 'Online Payments', route: null }
      ]
    }
  },
  computed: {
    stripeSettings () {
      return this.$store.getters['settings/stripe']
    },
    connected () {
      if (this.stripeSettings) {
        return this.stripeSettings.stripeConnected
      }
      return false
    },
    stripeConnectUrl () {
      if (this.stripeSettings) {
        return 'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=' + this.stripeSettings.stripeClientId + '&scope=read_write&state=' + this.stripeSettings.stripeConnectSetupToken
      }
      return null
    }
  },
  mounted () {
    this.isLoading = true
    this.$store.dispatch('settings/fetchStripe')
      .then(() => {
        this.isLoading = false
      })
  },
  components: { Breadcrumbs }
}
</script>
